import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { GridItem, Grid } from '../components/Grid';
import PostCard from '../components/Blog/PostCard';
import Hero from '../components/Hero';

const BlogSection = styled.div`
  margin-bottom: 2.4375rem;

  a:hover h3 {
    color: #000000;
  }

  p {
    margin-bottom: 0px;
  }

  h2 {
    margin-bottom: 1.437rem;
  }

  h2 span {
    font-weight: 300
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    line-height: 1.8125rem;
  }

  &:last-child {
    margin-bottom: 8.3125rem;
  }
`;
const HeroWrapper = styled.div`
  margin-top: 30px;
`;

const BlogIndex = ({ data, location }) => {
  const {
    customerStoriesBlogPosts,
    bestPracticeBlogPosts,
    productBlogPosts,
    highlightPosts,
    businessTipsBlogPosts,
    workSmarterBlogPosts,
  } = data;
  const customerStoriesPosts = customerStoriesBlogPosts.edges;
  const bestPracticePosts = bestPracticeBlogPosts.edges;
  const productPosts = productBlogPosts.edges;
  const highlightPost = highlightPosts.edges[0].node;
  const businessTipsPosts = businessTipsBlogPosts.edges;
  const workSmarterPosts = workSmarterBlogPosts.edges;
  return (
    <Layout location={location}>
      <SEO
        title="Blog | Workast - Use Cases, Project Management Best Practices, and More!"
        image={highlightPost.featuredImage}
        description="Check out our blog to learn the latest updates in the project management space, read customer stories, and learn about our product. Learn more."
      />
      <HeroWrapper>
        <Hero featuredImage={highlightPost.featuredImage}>
          <h1>
            <Link to={`/blog/${highlightPost.slug}/`}>
              {highlightPost.title}
            </Link>
          </h1>
        </Hero>
      </HeroWrapper>
      <BlogSection>
        <h2>
          <Link to="/blog/customer-stories/">
            Customer Stories
            &nbsp;|&nbsp;
            <span>Learn Workast</span>
            &nbsp; ►
          </Link>
        </h2>
        <Grid gridGap="4rem" minCol="280px">
          {customerStoriesPosts.map(({ node }) => {
            const title = node.title || node.slug;

            return (
              <GridItem key={node.slug}>
                <PostCard
                  title={title}
                  slug={node.slug}
                  featuredImage={node.featuredImage}
                  excerpt={node.excerpt.excerpt}
                />
              </GridItem>
            );
          })}
        </Grid>
      </BlogSection>
      <BlogSection>
        <h2>
          <Link to="/blog/product/">
            Product
            &nbsp;|&nbsp;
            <span>Learn Workast</span>
            &nbsp; ►
          </Link>
        </h2>
        <Grid gridGap="4rem" minCol="280px">
          {productPosts.map(({ node }) => {
            const title = node.title || node.slug;

            return (
              <GridItem key={node.slug}>
                <PostCard
                  title={title}
                  slug={node.slug}
                  featuredImage={node.featuredImage}
                  excerpt={node.excerpt.excerpt}
                />
              </GridItem>
            );
          })}
        </Grid>
      </BlogSection>
      <BlogSection>
        <h2>
          <Link to="/blog/best-practices/">
            Best Practices
            &nbsp;|&nbsp;
            <span>Project Management Tips and Tricks</span>
            &nbsp; ►
          </Link>
        </h2>
        <Grid gridGap="4rem" minCol="280px">
          {bestPracticePosts.map(({ node }) => {
            const title = node.title || node.slug;

            return (
              <GridItem key={node.slug}>
                <PostCard
                  title={title}
                  slug={node.slug}
                  featuredImage={node.featuredImage}
                  excerpt={node.excerpt.excerpt}
                />
              </GridItem>
            );
          })}
        </Grid>
      </BlogSection>
      <BlogSection>
        <h2>
          <Link to="/blog/business-tips/">
            Business Tips
            &nbsp;|&nbsp;
            <span>Tips to help you run a business</span>
            &nbsp; ►
          </Link>
        </h2>
        <Grid gridGap="4rem" minCol="280px">
          {businessTipsPosts.map(({ node }) => {
            const title = node.title || node.slug;

            return (
              <GridItem key={node.slug}>
                <PostCard
                  title={title}
                  slug={node.slug}
                  featuredImage={node.featuredImage}
                  excerpt={node.excerpt.excerpt}
                />
              </GridItem>
            );
          })}
        </Grid>
      </BlogSection>
      <BlogSection>
        <h2>
          <Link to="/blog/work-smarter/">
            Work Smarter
            &nbsp;|&nbsp;
            <span>Productivity insights delivered to your inbox</span>
            &nbsp; ►
          </Link>
        </h2>
        <Grid gridGap="4rem" minCol="280px">
          {workSmarterPosts.map(({ node }) => {
            const title = node.title || node.slug;

            return (
              <GridItem key={node.slug}>
                <PostCard
                  title={title}
                  slug={node.slug}
                  featuredImage={node.featuredImage}
                  excerpt={node.excerpt.excerpt}
                />
              </GridItem>
            );
          })}
        </Grid>
      </BlogSection>
    </Layout>
  );
};

BlogIndex.propTypes = {
  data: PropTypes.shape({
    customerStoriesBlogPosts: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            excerpt: PropTypes.shape({
              excerpt: PropTypes.string,
            }),
            featuredImage: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                srcSetWebp: PropTypes.string,
                srcWebp: PropTypes.string,
              }),
              description: PropTypes.string,
            }),
          }),
        })
      ),
    }).isRequired,
    productBlogPosts: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            excerpt: PropTypes.shape({
              excerpt: PropTypes.string,
            }),
            featuredImage: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                srcSetWebp: PropTypes.string,
                srcWebp: PropTypes.string,
              }),
              description: PropTypes.string,
            }),
          }),
        })
      ),
    }).isRequired,
    bestPracticeBlogPosts: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            excerpt: PropTypes.shape({
              excerpt: PropTypes.string,
            }),
            featuredImage: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                srcSetWebp: PropTypes.string,
                srcWebp: PropTypes.string,
              }),
              description: PropTypes.string,
            }),
          }),
        })
      ),
    }).isRequired,
    highlightPosts: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            excerpt: PropTypes.shape({
              excerpt: PropTypes.string,
            }),
            featuredImage: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                srcSetWebp: PropTypes.string,
                srcWebp: PropTypes.string,
              }),
              description: PropTypes.string,
            }),
          }),
        })
      ),
    }).isRequired,
    businessTipsBlogPosts: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            excerpt: PropTypes.shape({
              excerpt: PropTypes.string,
            }),
            featuredImage: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                srcSetWebp: PropTypes.string,
                srcWebp: PropTypes.string,
              }),
              description: PropTypes.string,
            }),
          }),
        })
      ),
    }).isRequired,
    workSmarterBlogPosts: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            excerpt: PropTypes.shape({
              excerpt: PropTypes.string,
            }),
            featuredImage: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                srcSetWebp: PropTypes.string,
                srcWebp: PropTypes.string,
              }),
              description: PropTypes.string,
            }),
          }),
        })
      ),
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    customerStoriesBlogPosts: allContentfulBlogPost(
      filter: {
        category: { elemMatch: { slug: { eq: "customer-stories" } } }
        node_locale: { eq: "en-US" }
      }
      sort: { fields: [publishDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          excerpt {
            excerpt
          }
          featuredImage {
            description
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    bestPracticeBlogPosts: allContentfulBlogPost(
      filter: {
        category: { elemMatch: { slug: { eq: "best-practices" } } }
        node_locale: { eq: "en-US" }
      }
      sort: { fields: [publishDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          excerpt {
            excerpt
          }
          featuredImage {
            description
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    productBlogPosts: allContentfulBlogPost(
      filter: {
        category: { elemMatch: { slug: { eq: "product" } } }
        node_locale: { eq: "en-US" }
      }
      sort: { fields: [publishDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          excerpt {
            excerpt
          }
          featuredImage {
            description
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    highlightPosts: allContentfulBlogPost(
      filter: {featuredInHome: {eq: true}}
      sort: { fields: [publishDate], order: DESC }
      limit: 1
    ) {
      edges {
        node {
          title
          slug
          excerpt {
            excerpt
          }
          featuredImage {
            description
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    businessTipsBlogPosts: allContentfulBlogPost(
      filter: {
        category: { elemMatch: { slug: { eq: "business-tips" } } }
        node_locale: { eq: "en-US" }
      }
      sort: { fields: [publishDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          excerpt {
            excerpt
          }
          featuredImage {
            description
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    workSmarterBlogPosts: allContentfulBlogPost(
      filter: {
        category: { elemMatch: { slug: { eq: "work-smarter" } } }
        node_locale: { eq: "en-US" }
      }
      sort: { fields: [publishDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          excerpt {
            excerpt
          }
          featuredImage {
            description
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
